<template>
  <div class="input-group">
    <slot></slot>
    <div v-if="append" class="input-group-append">
      <span class="input-group-text">{{ append }}</span>
    </div>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'InputGroup',
  props: {
    append: {
      type: String,
    },
  },
};
</script>
