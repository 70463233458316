<template>
  <img :src="src" class="img-thumbnail" />
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    src: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
